.fileManager {
    padding: 0;
    display: flex;
    
    flex-grow: 1;
    flex-direction: column;
}

.fileManager__box {
    
    flex-grow: 1;
    border: none;
}

.fileManager__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.fileManager__icon {
    margin-right: 10px;
}