.header {
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    padding: 10px;
    position: sticky;
    justify-content: space-between;
    align-items: center;
}
.header__search {
    align-items: center;
    width: 300px;
    display: flex;
    
    
    
}
.header__search input{ 
    height: 100%;
    flex-grow: 1;
    background-color: rgba(255,255,255,0.3);
    border:1px solid rgba(0,0,0,0.2) !important;
    border-radius: 999px;
    color: black;
    padding-left: 15px;
    padding: 10px;
    
}
.header__search input::placeholder{
    color: rgba(0,0,0,0.4);
    


 }
.header__navItems {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header__navItems .MuiSvgIcon-root {
    margin-right: 15px;
    margin-left: 15px;
}

.header__navItems .MuiAvatar-root{
    background-color: white;
}