.accounts {
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.accounts__box {
    
    border: none;
    flex-grow: 1;
}

.accounts__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.accounts__toolbar {
    padding-left: 10px;
    padding-right: 10px;
}