.deviceDetail {
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.deviceDetail__box{
    border: none;
    flex-grow: 1;
    position: relative;
}

.deviceDetail__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    min-height: 31px;

}

.deviceDetail__grid{
    background-color: aquamarine;
}

.deviceDetail__grid__alternate{
 background-color: blanchedalmond;
}

.deviceDetail__bottomButtomBar {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content:center;
}

.deviceDetail__form {
    font-size: 11px;
}