.leftBar {
    background-color: white;
    display: flex;
    flex: 0.15;
    min-width: 190px;
    flex-direction: column;
    border-right: 1px solid #ccc;
}
.leftBar > .leftBar__logo {
    text-align: center;
    padding: 5px;
}
.leftBar > .leftBar__logo > img {
    height: 45px;
}
.leftBar hr {
    height: 1px;
    border: 0;
    background-color: lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}