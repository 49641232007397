.clients {
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.clients__box {
    border: none;
    flex-grow: 1;
}

.clients__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

}