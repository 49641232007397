.dataGrid__cell {
        
}
.dataGrid__cell__center {
    text-align: center;
}

.MuiDataGrid-root {
    border: none !important;
    font-size: 10px;
}

.MuiDataGrid-cell {
    font-size: 12px;
}