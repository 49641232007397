.menuItem {
    color: #444;
    display: flex;
    font-size: 12px;
    align-items: center;
    padding-left: 2px;
    cursor: pointer;
}

.menuItem > span {
    font-weight: 500;
}

.menuItem:hover {
    background-color: #cecece;
    color: black;
    opacity: 0.9;
}

.menuItem__icon {
    padding: 10px;
    font-size: 15px !important;
}

.menuItem:hover .menuItem__icon {
    color: #0a2c56
}