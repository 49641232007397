.projects {
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.projects__box {
    flex-grow: 1;
    border: none;
}

.projects__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}