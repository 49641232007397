.accountDetail {
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.accountDetail__box {
    border:none;
    flex-grow: 1;
    overflow: auto;

    
}

.accountDetail__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
}

.accountDetail__formFields {
    padding: 20px;
    align-content: center;
    overflow: auto;
    margin-left: 250px;
    margin-right: 250px;
    
    
}

.accountDetail__bottomButtomBar{
    display: initial;
    margin-top: 15px;
}

@media (max-width: 960px) {
    .accountDetail__formFields {
    
        
        margin-left: 0;
        margin-right: 0;
        
        
    }
}