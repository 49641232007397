.login {
    display: flex;
    flex: 1;
    background-color: black;
    flex-direction: row-reverse;
    background-image: url(https://www.stevens-bolton.com/cms/photo/article/main_Construction2-779182138-resize.jpg);
    background-size: cover;
    background-position: center;
    
}

.login__rightPanel {
    background-color: #ffffffbe;
    backdrop-filter: blur(10px);
    min-width: 375px;
    flex: .33;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #666;
    padding: 20px;
}

.login__subLogo {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}


@media (max-width:800px) {
    
    .login__rightPanel {        
        flex: .5;
    }
}

@media (max-width:600px) {
    .login {
        flex-direction: column;
    }
    .login__rightPanel {
        flex: 1;
    }
}