.help {
    padding: 25px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.help__box__container {    
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.help__box__left {
    flex:.5;
    padding-top: 15px;
    padding-bottom: 15px;
}
.help__box__left > h4{
    display: flex;
    align-items: center;
}
.help__box__left > h4 > .MuiSvgIcon-root{
    color: red;
    font-size: 32px;
}
.help__box__left ul li {
    font-size: 14px;
    margin-top:5px;
    margin-bottom:5px;
}
.help__box__right {
    flex:.5;
    padding: 15px;
    background-color: white;
}
.help__box__right ul li {
    font-size: 14px;
    margin-top:5px;
    margin-bottom:5px;
}