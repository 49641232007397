@import url(//fonts.googleapis.com/css?family=Ubuntu:300,400,600,400italic);
* {
  margin: 0;
}
.app {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.form-control {
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem;
  box-sizing: border-box;
}

.form-control-sm {
  height: calc(1.9375rem + 2px);
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-sm, select.form-control-sm {
  font-size: .75rem;
  height: 31px;
}

.loading {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  border: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  z-index: 999;
}

*, ::after, ::before {
  box-sizing: initial;
}

.loading span {
  background-color: white;
  box-shadow: white 0px 0px 30px 24px;
}

body {
  margin: 0;

  font-family: "Ubuntu", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
  color: #656565;
  font-size: 0.875rem;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa;

}

.btn-sm svg {
  font-size: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {

  display: flex;
  flex-direction: column;
  height: 100vh;

}

.drawer {
  display: flex;  
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
  flex-direction: row-reverse;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.drawer-right {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0px 18px 14px 8px rgb(0 0 0 / 20%);
}

.drawer-full {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vw;
  
  
}

.drawer-header {
  font-size: 18px;
  font-weight: bold;
}

.drawer-body {
  padding-top: 5px;
  padding-bottom: 5px;
  flex-grow: 1;

}

.drawer-footer {
  background-color: #f5f5f5;
}

.text-sm {
  font-size: 0.74375rem;
}

.topnavbar {
  background-color: rgb(9, 47, 87) !important;
  background-image: none !important;
}

.logo {
  max-height: 30px;
}


.timeline__custom {
  border-left: 3px solid #727cf5;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(114, 124, 245, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 40%;
}

@media (max-width: 767px) {
  .timeline__custom {
      max-width: 98%;
      padding: 25px;
  }
}

.timeline__custom h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline__custom h2,
.timeline__custom h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline__custom .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline__custom .event {
      padding-top: 30px;
  }
}

.timeline__custom .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline__custom .event:before,
.timeline__custom .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline__custom .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline__custom .event:before {
      left: 0px;
      text-align: left;
  }
}

.timeline__custom .event:after {
  -webkit-box-shadow: 0 0 0 3px #727cf5;
  box-shadow: 0 0 0 3px #727cf5;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline__custom .event:after {
      left: -31.8px;
  }
}

.rtl .timeline__custom {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #727cf5;
}

.rtl .timeline__custom .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline__custom .event::after {
  left: 0;
  right: -55.8px;
}

