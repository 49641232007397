.mainContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mainContainer h3{

    

}